import { Api } from "./api";

export default {
  single: (payload) =>
    Api.post(`/v3/shipping/check/cost/single`, payload.data, {
      headers: {
        "content-type": "application/json",
        token: payload.token,
      },
    }),
  multiple: (payload) =>
    Api.post(`/v3/shipping/check/cost/multiple`, payload.data, {
      headers: {
        "content-type": "application/json",
        token: payload.token,
      },
    }),
  cod_fee: (payload) =>
    Api.post(`/v3/shipping/check/cod-fee`, payload.data, {
      headers: {
        "content-type": "application/json",
        token: payload.token,
      },
    }),
};
