import types from "./constant";

const initialState = {
  data: [],
  cities: [],
  schedules: {
    data: [],
    loading: false,
  },
  tracking: {
    data: {},
    loading: false,
  },
  cost: {
    data: [],
    loading: false,
  },
  courier: {
    data: {},
    loading: false,
  },
  updated: false,
  isLoadingCities: false,
  isLoadingShipping: false,
  isLoadingUpdate: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SHIPPING_SUCCESS:
      return { ...state, data: action.payload };
    case types.IS_LOADING_GET_SHIPPING:
      return { ...state, isLoadingShipping: action.payload };
    case types.GET_CITIES_SUCCESS:
      return { ...state, cities: action.payload };
    case types.IS_LOADING_GET_CITIES:
      return { ...state, isLoadingCities: action.payload };
    case types.UPDATE_SHIPPING_SUCCESS:
      return { ...state, data: action.payload };
    case types.IS_LOADING_UPDATE_SHIPPING:
      return { ...state, isLoadingUpdate: action.payload };
    case types.UPDATED_SHIPPING:
      return { ...state, updated: action.payload };
    case types.GET_SCHEDULES_PICKUP_SUCCESS:
      return {
        ...state,
        schedules: {
          ...state.schedules,
          data: action.payload,
        },
      };
    case types.IS_LOADING_GET_SCHEDULES_PICKUP:
      return {
        ...state,
        schedules: {
          ...state.schedules,
          loading: action.payload,
        },
      };
    case types.RESET_SCHEDULES_PICKUP:
      return { ...state, schedules: initialState.schedules };
    case types.CHECK_TRACKING_SUCCESS:
      return {
        ...state,
        tracking: {
          ...state.tracking,
          data: action.payload,
        },
      };
    case types.IS_LOADING_CHECK_TRACKING:
      return {
        ...state,
        tracking: {
          ...state.tracking,
          loading: action.payload,
        },
      };
    case types.RESET_CHECK_TRACKING:
      return { ...state, tracking: initialState.tracking };
    case types.CHECK_COSTS_SUCCESS:
      return {
        ...state,
        cost: {
          ...state.cost,
          data: action.payload,
        },
      };
    case types.IS_LOADING_CHECK_COSTS:
      return {
        ...state,
        cost: {
          ...state.cost,
          loading: action.payload,
        },
      };
    case types.RESET_CHECK_COSTS:
      return { ...state, cost: initialState.cost };

    case types.CHECK_COST_SINGLE_SUCCESS:
      return {
        ...state,
        courier: {
          ...state.courier,
          data: action.payload,
        },
      };
    case types.IS_LOADING_CHECK_COST_SINGLE:
      return {
        ...state,
        courier: {
          ...state.courier,
          loading: action.payload,
        },
      };
    case types.RESET_CHECK_COST_SINGLE:
      return { ...state, courier: initialState.courier };

    default:
      return state;
  }
};

export const getShipping = (payload) => ({
  type: types.GET_SHIPPING,
  payload,
});
export const getShippingSuccess = (payload) => ({
  type: types.GET_SHIPPING_SUCCESS,
  payload,
});
export const setIsLoadingShipping = (payload) => ({
  type: types.IS_LOADING_GET_SHIPPING,
  payload,
});

export const updateShipping = (payload) => ({
  type: types.UPDATE_SHIPPING,
  payload,
});
export const updateShippingSuccess = (payload) => ({
  type: types.UPDATE_SHIPPING_SUCCESS,
  payload,
});
export const updatedShipping = (payload) => ({
  type: types.UPDATED_SHIPPING,
  payload,
});
export const setIsLoadingUpdateShipping = (payload) => ({
  type: types.IS_LOADING_UPDATE_SHIPPING,
  payload,
});

export const getCities = (payload) => ({
  type: types.GET_CITIES,
  payload,
});
export const getCitiesSuccess = (payload) => ({
  type: types.GET_CITIES_SUCCESS,
  payload,
});
export const setIsLoadingCities = (payload) => ({
  type: types.IS_LOADING_GET_CITIES,
  payload,
});

export const getSchedulesPickup = (payload) => ({
  type: types.GET_SCHEDULES_PICKUP,
  payload,
});
export const getSchedulesPickupSuccess = (payload) => ({
  type: types.GET_SCHEDULES_PICKUP_SUCCESS,
  payload,
});
export const setIsLoadingGetSchedulesPickup = (payload) => ({
  type: types.IS_LOADING_GET_SCHEDULES_PICKUP,
  payload,
});
export const resetSchedulesPickup = (payload) => ({
  type: types.RESET_SCHEDULES_PICKUP,
  payload,
});

export const checkTracking = (payload) => ({
  type: types.CHECK_TRACKING,
  payload,
});
export const checkTrackingSuccess = (payload) => ({
  type: types.CHECK_TRACKING_SUCCESS,
  payload,
});
export const setIsLoadingCheckTracking = (payload) => ({
  type: types.IS_LOADING_CHECK_TRACKING,
  payload,
});
export const resetCheckTracking = (payload) => ({
  type: types.RESET_CHECK_TRACKING,
  payload,
});

export const checkCosts = (payload) => ({
  type: types.CHECK_COSTS,
  payload,
});
export const checkCostsSuccess = (payload) => ({
  type: types.CHECK_COSTS_SUCCESS,
  payload,
});
export const setIsLoadingCheckCosts = (payload) => ({
  type: types.IS_LOADING_CHECK_COSTS,
  payload,
});
export const resetCheckCosts = (payload) => ({
  type: types.RESET_CHECK_COSTS,
  payload,
});

export const checkCostSingle = (payload) => ({
  type: types.CHECK_COST_SINGLE,
  payload,
});
export const checkCostSingleSuccess = (payload) => ({
  type: types.CHECK_COST_SINGLE_SUCCESS,
  payload,
});
export const setIsLoadingCheckCostSingle = (payload) => ({
  type: types.IS_LOADING_CHECK_COST_SINGLE,
  payload,
});
export const resetCheckCostSingle = (payload) => ({
  type: types.RESET_CHECK_COST_SINGLE,
  payload,
});
